var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      staticStyle: { padding: "16px" }
    },
    [
      _c(
        "el-card",
        [
          _c(
            "el-form",
            {
              ref: "queryForm",
              attrs: {
                model: _vm.queryParams,
                size: "small",
                "label-width": "90px"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "formModel" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "工单号", prop: "ticketId" } },
                    [
                      _c("el-input", {
                        staticClass: "formItem",
                        attrs: { placeholder: "请输入工单号", clearable: "" },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          }
                        },
                        model: {
                          value: _vm.queryParams.ticketId,
                          callback: function($$v) {
                            _vm.$set(_vm.queryParams, "ticketId", $$v)
                          },
                          expression: "queryParams.ticketId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "工单名称", prop: "title" } },
                    [
                      _c("el-input", {
                        staticClass: "formItem",
                        attrs: { placeholder: "请输入工单名称", clearable: "" },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          }
                        },
                        model: {
                          value: _vm.queryParams.title,
                          callback: function($$v) {
                            _vm.$set(_vm.queryParams, "title", $$v)
                          },
                          expression: "queryParams.title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "一级机房", prop: "bizAreaName" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          options: _vm.depOptions,
                          props: {
                            multiple: true,
                            expandTrigger: "hover",
                            label: "dept_name",
                            value: "dept_name",
                            children: "children"
                          },
                          "collapse-tags": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.queryParams.bizAreaName,
                          callback: function($$v) {
                            _vm.$set(_vm.queryParams, "bizAreaName", $$v)
                          },
                          expression: "queryParams.bizAreaName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "工单状态", prop: "state" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择工单状态",
                            clearable: ""
                          },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleQuery($event)
                            }
                          },
                          model: {
                            value: _vm.queryParams.state,
                            callback: function($$v) {
                              _vm.$set(_vm.queryParams, "state", $$v)
                            },
                            expression: "queryParams.state"
                          }
                        },
                        _vm._l(_vm.orderStatusOptions, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.value, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "超时状态", prop: "isTimeout" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择超时状态",
                            clearable: ""
                          },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleQuery($event)
                            }
                          },
                          model: {
                            value: _vm.queryParams.isTimeout,
                            callback: function($$v) {
                              _vm.$set(_vm.queryParams, "isTimeout", $$v)
                            },
                            expression: "queryParams.isTimeout"
                          }
                        },
                        _vm._l(_vm.timeoutStatusOptions, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.value, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "结单时间" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "formItem",
                        attrs: {
                          type: "daterange",
                          "value-format": "yyyy-MM-dd",
                          "range-separator": "至",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          clearable: ""
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          }
                        },
                        model: {
                          value: _vm.statementTimeRange,
                          callback: function($$v) {
                            _vm.statementTimeRange = $$v
                          },
                          expression: "statementTimeRange"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "创建时间", prop: "createdTimeRange" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "formItem",
                        attrs: {
                          type: "daterange",
                          "value-format": "yyyy-MM-dd",
                          "range-separator": "至",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          clearable: ""
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          }
                        },
                        model: {
                          value: _vm.createdTimeRange,
                          callback: function($$v) {
                            _vm.createdTimeRange = $$v
                          },
                          expression: "createdTimeRange"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否催办", prop: "is_remind" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", clearable: "" },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleQuery($event)
                            }
                          },
                          model: {
                            value: _vm.queryParams.is_remind,
                            callback: function($$v) {
                              _vm.$set(_vm.queryParams, "is_remind", $$v)
                            },
                            expression: "queryParams.is_remind"
                          }
                        },
                        _vm._l(_vm.remindOptions, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.value, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "维修类型", prop: "repairType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择维修类型",
                            clearable: ""
                          },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleQuery($event)
                            }
                          },
                          model: {
                            value: _vm.queryParams.repairType,
                            callback: function($$v) {
                              _vm.$set(_vm.queryParams, "repairType", $$v)
                            },
                            expression: "queryParams.repairType"
                          }
                        },
                        _vm._l(_vm.repairTypeOptions, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.value, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "替换类型", prop: "replaceType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择替换类型",
                            clearable: ""
                          },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleQuery($event)
                            }
                          },
                          model: {
                            value: _vm.queryParams.replaceType,
                            callback: function($$v) {
                              _vm.$set(_vm.queryParams, "replaceType", $$v)
                            },
                            expression: "queryParams.replaceType"
                          }
                        },
                        _vm._l(_vm.replaceTypeOptions, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.value, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "formItem btns" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            icon: "el-icon-search"
                          },
                          on: { click: _vm.handleQuery }
                        },
                        [_vm._v("搜索 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleFormRest("queryForm")
                            }
                          }
                        },
                        [_vm._v("重置 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "15px", "padding-bottom": "20px" } },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-download",
                size: "small",
                type: "primary"
              },
              on: { click: _vm.handleExport }
            },
            [_vm._v("导出 ")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: { data: _vm.list }
            },
            [
              _c("el-table-column", {
                attrs: { property: "ticketId", width: "160", label: "工单号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "ticketId",
                            on: {
                              click: function($event) {
                                return _vm.getDetail(scope.row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(scope.row.ticketId))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { property: "title", width: "160", label: "工单名称" }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "bizAreaName",
                  width: "100",
                  label: "一级机房"
                }
              }),
              _c("el-table-column", {
                attrs: { property: "repairType", label: "维修类型" }
              }),
              _c("el-table-column", {
                attrs: { property: "replaceType", label: "替换类型" }
              }),
              _c("el-table-column", {
                attrs: { property: "state", label: "工单状态" }
              }),
              _c("el-table-column", {
                attrs: { property: "handler", label: "当前处理人" }
              }),
              _c("el-table-column", {
                attrs: { property: "deadline", label: "截止时间" }
              }),
              _c("el-table-column", {
                attrs: { property: "countdown", label: "倒计时" }
              }),
              _c("el-table-column", {
                attrs: { property: "isTimeout", label: "超时状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "span",
                          {
                            style:
                              scope.row.isTimeout &&
                              scope.row.isTimeout.includes("临近超时")
                                ? "color:#f49b0d;font-weight:bold;"
                                : scope.row.isTimeout &&
                                  scope.row.isTimeout.includes("已超时")
                                ? "color:#f40d0e;font-weight:bold;"
                                : "color:#1381e6;font-weight:bold;"
                          },
                          [_vm._v(_vm._s(scope.row.isTimeout))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "催办记录" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.isRemind
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.getRecord(scope.row)
                                  }
                                }
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { property: "completeTime", label: "结单时间" }
              }),
              _c("el-table-column", {
                attrs: { property: "createTime", label: "创建时间" }
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  align: "left",
                  label: "操作",
                  width: "100"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.remind(scope.row)
                              }
                            }
                          },
                          [_vm._v("催办")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { "margin-top": "20px" },
            attrs: {
              "current-page": _vm.pageParams.page,
              layout: "total, sizes, prev, pager, next, jumper",
              "page-sizes": [25, 50, 100],
              "page-size": _vm.pageParams.limit,
              total: _vm.total
            },
            on: {
              "update:currentPage": function($event) {
                return _vm.$set(_vm.pageParams, "page", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.pageParams, "page", $event)
              },
              "update:pageSize": function($event) {
                return _vm.$set(_vm.pageParams, "limit", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.pageParams, "limit", $event)
              },
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "催办记录",
            visible: _vm.recordVisible,
            width: "1000px",
            "before-close": _vm.recordClose,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.recordVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.remindList } },
            [
              _c("el-table-column", {
                attrs: { property: "ticketId", label: "工单" }
              }),
              _c("el-table-column", {
                attrs: { property: "remindStaff", label: "催办对象" }
              }),
              _c("el-table-column", {
                attrs: { property: "remindMessage", label: "催办内容" }
              }),
              _c("el-table-column", {
                attrs: { property: "remindPerson", label: "催办人" }
              }),
              _c("el-table-column", {
                attrs: { property: "createTime", label: "催办时间" }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.recordVisible = false
                    }
                  }
                },
                [_vm._v("关 闭")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "催办通知",
            visible: _vm.remindVisible,
            width: "700px",
            "before-close": _vm.remindClose,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.remindVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "工单单号：", prop: "ticketId" } },
                [
                  _c(
                    "span",
                    { staticStyle: { "font-size": "14px", color: "#333" } },
                    [_vm._v(_vm._s(_vm.ruleForm.ticketId))]
                  )
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "工单名称：", prop: "processTitle" } },
                [
                  _c(
                    "span",
                    { staticStyle: { "font-size": "14px", color: "#333" } },
                    [_vm._v(_vm._s(_vm.ruleForm.processTitle))]
                  )
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "通知对象：", prop: "remindStaff" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.ruleForm.remindStaff,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "remindStaff", $$v)
                        },
                        expression: "ruleForm.remindStaff"
                      }
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "1" } }, [
                        _vm._v("当前处理人")
                      ])
                    ],
                    1
                  ),
                  _c("span", [_vm._v(" 催区管 ")]),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.selectedOption,
                        callback: function($$v) {
                          _vm.selectedOption = $$v
                        },
                        expression: "selectedOption"
                      }
                    },
                    _vm._l(_vm.options, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "通知内容：", prop: "remindMessage" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入",
                      maxlength: "1000",
                      rows: "3"
                    },
                    model: {
                      value: _vm.ruleForm.remindMessage,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "remindMessage", $$v)
                      },
                      expression: "ruleForm.remindMessage"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "padding-left": "20px" } },
            [
              _c("h4", [_vm._v("快捷语句：")]),
              _vm._l(_vm.shortcutStatement, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "item-shortcut-statement" },
                  [
                    _c("span", [_vm._v(_vm._s(item))]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.add(item)
                          }
                        }
                      },
                      [_vm._v("添加")]
                    )
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("提 交")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.remindVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }